import { RouterProvider } from 'react-router-dom';
import { AllRoutes } from './Routes';

const MainRoutes = () => {
    return (
        <>
            <RouterProvider router={AllRoutes} />
        </>
    )
}

export default MainRoutes;
