import PageDeleteProfile from "./Page_Delete_Profile";
import PageEditProfile from "./Page_Edit_Profile";
import PageMyCourses from "./Page_MyCourses";
import PagePayouts from "./Page_Payouts";
import PageQuiz from "./Page_Quiz";
import PageReview from "./Page_Review";
import PageSetting from "./Page_Setting";
import PageStudentList from "./Page_StudentList";
import PageDashboard from "./Page_dashboard";
import PageOrder from "./Page_order";
import PageEarning from "./page_Earning";

export const sideBarData = [
    {
        key: 1,
        icon: 'bi bi-ui-checks-grid',
        title: 'Dashboard',
        slug: 'dashboard',
        path: '/Dashboard',
        component: <PageDashboard />,
        className: 'list-group-item',
    },
    {
        key: 2,
        icon: 'bi bi-basket',
        title: 'My Courses',
        slug: 'my-courses',
        path: '/Dashboard',
        component: <PageMyCourses />,
        className: 'list-group-item',
    },
    {
        key: 3,
        icon: 'bi bi-question-diamond',
        title: 'Quiz',
        slug: 'quiz',
        path: '/Dashboard',
        component: <PageQuiz />,
        className: 'list-group-item',
    },
    {
        key: 4,
        icon: 'bi bi-graph-up',
        title: 'Earnings',
        slug: 'earnings',
        path: '/Dashboard',
        component: <PageEarning />,
        className: 'list-group-item',
    },
    {
        key: 5,
        icon: 'bi bi-people',
        title: 'Students',
        slug: 'students',
        path: '/Dashboard',
        component: <PageStudentList />,
        className: 'list-group-item',
    },
    {
        key: 6,
        icon: 'bi bi-folder-check',
        title: 'Orders',
        slug: 'orders',
        path: '/Dashboard',
        component: <PageOrder />,
        className: 'list-group-item',
    },
    {
        key: 7,
        icon: 'bi bi-star',
        title: 'Reviews',
        slug: 'reviews',
        path: '/Dashboard',
        component: <PageReview />,
        className: 'list-group-item',
    },
    {
        key: 8,
        icon: 'bi bi-pencil-square',
        title: 'Edit Profile',
        slug: 'edit-profile',
        path: '/Dashboard',
        component: <PageEditProfile />,
        className: 'list-group-item',
    },
    {
        key: 9,
        icon: 'bi bi-wallet2',
        title: 'Payouts',
        slug: 'payouts',
        path: '/Dashboard',
        component: <PagePayouts />,
        className: 'list-group-item',
    },
    {
        key: 10,
        icon: 'bi bi-gear',
        title: 'Settings',
        slug: 'settings',
        path: '/Dashboard',
        component: <PageSetting />,
        className: 'list-group-item',
    },
    {
        key: 11,
        icon: 'bi bi-trash',
        title: 'Delete Profile',
        slug: 'delete-profile',
        path: '/Dashboard',
        component: <PageDeleteProfile />,
        className: 'list-group-item',
    },
    {
        key: 12,
        icon: 'fas fa-sign-out-alt',
        title: 'Sign Out',
        slug: 'sign-out',
        path: '/Dashboard',
        component: <></>,
        className: 'list-group-item text-danger bg-danger-soft-hover',
    },
];