import React, { Suspense } from 'react';
// import './assets/vendor/font-awesome/css/all.min.css';
// import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
// import './assets/vendor/tiny-slider/tiny-slider.css';
// import './assets/vendor/glightbox/css/glightbox.css';
// import './assets/css/style.css';
import MainRoutes from './Routes';


function App() {
  return (
    <Suspense>
      <MainRoutes />
    </Suspense>
  );
}

export default App;
