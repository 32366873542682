// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { RegisterByEmail } from "./Authentication";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCkwAzCV7Z9uEggt8n9mj9Oe00UyWTSy3I",
    authDomain: "cica-web.firebaseapp.com",
    projectId: "cica-web",
    storageBucket: "cica-web.appspot.com",
    messagingSenderId: "37991921506",
    appId: "1:37991921506:web:48b28e235419e7e0d99e3b",
    measurementId: "G-G9FXCD361G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);

export default app;

// google login
export const GoogleLogin = ({ dispatch }: { dispatch: any }) => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential?.accessToken;
            // The signed-in user info.
            const user = result.user;
            const email = user?.email;
            dispatch(RegisterByEmail({ emailid: email }))
            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            console.log("Error", errorCode, errorMessage, email, credential);
            // ...
        });
}


