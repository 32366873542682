interface CustomCardProps extends React.HTMLAttributes<HTMLDivElement> {
    title?: string;
    description?: string;
    image?: string;
    children?: React.ReactNode;
    mainCardProps?: React.HTMLAttributes<HTMLDivElement>;
    imageProps?: React.HTMLAttributes<HTMLImageElement>;
    bodyProps?: React.HTMLAttributes<HTMLDivElement>;
    titleProps?: React.HTMLAttributes<HTMLHeadingElement>;
    descriptionProps?: React.HTMLAttributes<HTMLParagraphElement>;
    childrenBodyContainerProps?: React.HTMLAttributes<HTMLDivElement>;
}

const CustomCard: React.FC<CustomCardProps> = ({ title, description, image, children, mainCardProps, bodyProps, titleProps, descriptionProps, imageProps, childrenBodyContainerProps }) => {
    return (
        <div className="card shadow" {...mainCardProps} >
            <div className="card-body" {...bodyProps} >
                {!!title && <h5 className="card-title" {...titleProps} >{title}</h5>}
                {!!description && <p className="card-text" {...descriptionProps} >{description}</p>}
                {!!image && <img src={image} className="card-img-top" alt="..." {...imageProps} />}
                <div {...childrenBodyContainerProps} >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default CustomCard;