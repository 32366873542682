import React from 'react'
import { BannerHome, Layout, PopularCoursesHome } from '../Components'
import CounterHome from '../Components/Pages/Home/CounterHome'
import FeedbackHome from '../Components/Pages/Home/FeedbackHome'

const Home = () => {

	return (
		<>
			<Layout>
				{/* <main> */}
				<BannerHome />
				<CounterHome />
				<PopularCoursesHome />
				<FeedbackHome />
				{/* </main> */}

			</Layout>
		</>
	)
}

export default Home
