import Header from "./Header";
import Footer from "./Footer";

interface LayoutInterface {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutInterface> = ({ children }) => {
    return (
        <>
            <Header />
            <div className="container m-auto p-3">
                {children}
            </div>
            <Footer />
        </>
    );
}

export default Layout;