import { LineChart } from "@mui/x-charts"
import CustomCarousel from "../../Common/CustomCarousel"
import CustomCard from "../../Common/CustomCard"
import { Box, CircularProgress, useMediaQuery } from "@mui/material"

const DashboardLeft: React.FC = () => {

    const userCardDetails = [
        {
            key: 1,
            title: 'Name',
            value: 'undefined',
            icon: 'bi bi-house-check'
        },
        {
            key: 2,
            title: 'Rank',
            value: 'undefined',
            icon: 'bi bi-award'
        },
        {
            key: 3,
            title: 'Phone',
            value: 'undefined',
            icon: 'bi bi-telephone-fill'
        },
        {
            key: 4,
            title: 'Current Salary',
            value: 'undefined',
            icon: 'bi bi-cash-coin'
        },
        {
            key: 5,
            title: 'Expected Salary',
            value: 'undefined',
            icon: 'bi bi-cash-coin'
        }
    ]

    function LineChartComponent() {
        return (
            <LineChart
                xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                series={[
                    {
                        data: [2, 5.5, 2, 8.5, 1.5, 5],
                    },
                ]}
                height={250}
            />
        )
    }

    function ListComponent() {
        return (<ul className="list-group list-group-flush">
            {Array(5).fill(0).map((_, index) => {
                return (
                    <li className="list-group-item">item {index}</li>
                )
            })}
        </ul>)
    }

    const cardSetting = {
        mainCardProps: {
            style: { height: 300 }
        },
    }

    const CarouselUser = [
        {
            key: 1,
            // title: 'Performance',
            component: <CustomCard title="Performance" {...cardSetting}><LineChartComponent /></CustomCard>,
            className: 'active',
            slideInterval: 2000
        },
        {
            key: 2,
            // title: 'System',
            component: <CustomCard title="System" {...cardSetting}><ListComponent /></CustomCard>,
            className: 'active',
            slideInterval: 2000
        }
    ]

    return (
        <div className="row">
            <div className="card shadow align-items-center pt-2">
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress value={95} variant="determinate" style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        zIndex: 1,
                    }} />
                    <div className="avatar avatar-xxxl">
                        <img src="https://via.placeholder.com/150" alt="..." className="avatar-img rounded-circle" />
                    </div>
                </Box>
                <div className="card-body w-100">
                    <ul className="list-group list-group-flush">
                        {
                            userCardDetails.map((item) => {
                                return (
                                    <li key={item.key} className="list-group-item">
                                        <div className="d-flex">
                                            <i className={item.icon}></i>
                                            <strong className="list-group-item-action">{item.title} :</strong>
                                            <strong className="text-secondary">{item.value}</strong>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            {/* Carousel */}
            <div className="mt-3 w-100">
                <CustomCarousel CarouseId="dashboardLeft" CarouselArray={CarouselUser} />
            </div>
        </div>
    )
}

export default DashboardLeft;