import { createBrowserRouter } from 'react-router-dom';
import Home from '../Pages/Home';
import { SignIn, SignUp } from '../Components';
import DashPage from '../Pages/DashPage';
import StudentDashboardIndex from '../Pages/StudentDashboardIndex';
import ErrorPage from '../Pages/ErrorPage';

export const AllRoutes = createBrowserRouter([
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '*',
        element: <ErrorPage />,
    },
    {
        path: '/UserDashboard',
        element: <DashPage />,
    },
    {
        path: '/Dashboard',
        element: <StudentDashboardIndex />,
    },
    {
        path: '/sign-in',
        element: <SignIn />,
    },
    {
        path: '/sign-up',
        element: <SignUp />,
    }
]);