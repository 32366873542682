import React from 'react'
import DashboardPageSideBar from './DashboardPageSideBar'
import PageDashboard from './Page_dashboard'
import { sideBarData } from './LocalData';

interface DashboardPageBodyProps {
    setActiveTab: (tab: string) => void;
    activeTab: string;
}

const DashboardPageBody: React.FC<DashboardPageBodyProps> = ({ setActiveTab, activeTab }) => {

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    }

    return (
        <>
            <section className="pt-0">
                <div className="container">
                    <div className="row">
                        {/* Left sidebar START */}
                        <div className="col-xl-3">
                            <DashboardPageSideBar onTabChange={(tab => setActiveTab(tab))} selectedTab={activeTab} />
                        </div>
                        {/* Left sidebar END */}
                        {/* Main content START */}
                        <div className="col-xl-9">
                            {
                                sideBarData.map((Item, index) => {
                                    return Item.slug === activeTab ? (
                                        <div key={index}>
                                            {Item.component}
                                        </div>
                                    ) : null;
                                })
                            }
                        </div>
                        {/* Main content END */}
                    </div>{/* Row END */}
                </div>
            </section>

        </>
    )
}

export default DashboardPageBody
