import React from 'react'
import DashboardPageBanner from './DashboardPageBanner'
import DashboardPageBody from './DashboardPageBody'

const Index = () => {
    const [activeTab, setActiveTab] = React.useState('dashboard');
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    return (
        <>
            <DashboardPageBanner activeTab={activeTab} />
            <DashboardPageBody activeTab={activeTab} setActiveTab={(tab) => handleTabChange(tab)} />
        </>
    )
}

export default Index
