import React from 'react'
import { Link } from 'react-router-dom'
import { sideBarData } from './LocalData'

interface DashboardPageSideBarProps {
    onTabChange: (tab: string) => void;
    selectedTab: string;
}

const DashboardPageSideBar: React.FC<DashboardPageSideBarProps> = ({ onTabChange, selectedTab }) => {

    const onClick = (tab: any) => {
        onTabChange(tab.slug)
    }

    return (
        <>
            {/* Responsive offcanvas body START */}
            <div className="offcanvas-xl offcanvas-end" tabIndex={-1} id="offcanvasSidebar">
                {/* Offcanvas header */}
                <div className="offcanvas-header bg-light">
                    <h5 className="offcanvas-title" id="offcanvasNavbarLabel">My profile</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close" />
                </div>
                {/* Offcanvas body */}
                <div className="offcanvas-body p-3 p-xl-0">
                    <div className="bg-dark border rounded-3 pb-0 p-3 w-100">
                        {/* Dashboard menu */}
                        <div className="list-group list-group-dark list-group-borderless">
                            {
                                sideBarData.map((item, index) => {
                                    return (
                                        <li key={index} className={`${item.className} ${selectedTab === item.slug && 'active'}`} onClick={() => onClick(item)}><i className={`${item.icon} fa-fw me-2`} onClick={() => onClick(item)} />{item.title}</li>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* Responsive offcanvas body END */}
        </>
    )
}

export default DashboardPageSideBar
