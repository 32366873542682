import { createSlice } from "@reduxjs/toolkit";
import { AuthenticationInterface } from "./Interface";


const initialState: AuthenticationInterface = {
    user: {
        username: '',
        name: '',
        mobno: '',
        emailid: '',
        type: '',
        branch: '',
        sex: '',
        semester: '',
        sub_org_type: '',
        about_you: '',
        user_address: '',
        user_city: '',
        user_state: '',
        user_pin_code: '',
    },
    token: "",
    isLoggedIn: false,
    error: "",
    loading: {
        api: false,
        local: false,
        custom: false,
    },
    inputFields: {
        username: '',
        password: '',
    }
};

const Authentication = createSlice({
    name: "Authentication",
    initialState,
    reducers: {
        setAuthenticationLoading: (state, action) => {
            state.loading.local = action.payload;
        },
        setEmailTextField: (state, action) => {
            state.inputFields.username = action.payload;
        },
        setPasswordTextField: (state, action) => {
            state.inputFields.password = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase("auth/Login/pending", (state, action) => {
                state.loading.api = true;
            })
            .addCase("auth/Login/fulfilled", (state, action: any) => {
                console.log(action.payload, "action.payload")
                state.loading.api = false;
                // state.isLoggedIn = true;
                // state.token = action.payload.token;
                // state.user = action.payload.user;
            })
            .addCase("auth/Login/rejected", (state, action: any) => {
                state.loading.api = false;
                state.error = action.payload;
            })
            .addCase("auth/RegisterByEmail/pending", (state,action:any)=>{
                state.loading.api = true;
            })
            .addCase("auth/RegisterByEmail/fulfilled", (state, action:any)=>{
                state.loading.api = false;
            })
            .addCase("auth/RegisterByEmail/rejected", (state, action:any)=>{
                state.loading.api = false;
                state.error = action.payload;
            })
    },
});

export const {
    setAuthenticationLoading,
    setEmailTextField,
    setPasswordTextField,
} = Authentication.actions;
export default Authentication.reducer;