import React from 'react'
import CustomCarousel from '../../Common/CustomCarousel';

const DashPageCenter: React.FC = () => {
    return (
        <div>
            <CustomCarousel CarouseId='dashboardCenter1'/>
            <CustomCarousel CarouseId='dashboardCenter2'/>
        </div>
    )
}

export default DashPageCenter;
