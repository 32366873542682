import React from 'react'
import { Layout, StudentDashboard } from '../Components'

const StudentDashboardIndex = () => {
    return (
        <Layout>
            <StudentDashboard />
        </Layout>
    )
}

export default StudentDashboardIndex
