import axios from "axios";
import { useSelector } from "react-redux";
import { AuthenticationInterface } from "../Slices/Interface";

const baseURL = 'https://sites.cynayd.com/collegespike_rest_api/public/';

export const Instance = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

// export const Instance = () => {
//     const { token } = useSelector((state: any) => state.auth) as AuthenticationInterface;
//     return axios.create({
//         baseURL: baseURL,
//         headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json',
//             'Authorization': `Bearer ${token}`
//         }
//     });
// };