
import './style/mainStyle.scss';

interface CustomCarouselProps {
    CarouselArray?: {
        title?: string;
        titleClassName?: string;
        component: React.ReactNode;
        className?: string;
        slideInterval?: 2000 | 3000 | 4000 | 5000 | number;
    }[];
    CarouseId: string;
}

const CustomCarousel: React.FC<CustomCarouselProps> = ({ CarouselArray, CarouseId }) => {
    return (
        <div>
            <div id={CarouseId} className="carousel slide card shadow customCarousel" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {
                        CarouselArray?.map((item, index) => {
                            return <div className={`carousel-item ${item.className}`} key={index} data-bs-interval={item.slideInterval}>
                                <h4 className={`${item.titleClassName ? item.titleClassName : "text-center mb-0"} ${item.title ? '' : 'd-none'}`}>{item.title}</h4>
                                {item.component}
                            </div>
                        })
                    }
                </div>
                <button className="carousel-control-prev customCarouselButton" type="button" data-bs-target={`#${CarouseId}`} data-bs-slide="prev">
                    <span className="bi bi-arrow-left-circle-fill fs-1 ms-n5 text-primary bg-opacity-10" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next customCarouselButton" type="button" data-bs-target={`#${CarouseId}`} data-bs-slide="next">
                    <span className="bi bi-arrow-right-circle-fill fs-1 me-n5 text-primary bg-opacity-10" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    )
};

export default CustomCarousel;

CustomCarousel.defaultProps = {
    CarouselArray: [
        {
            // title: 'First Slide',
            component: <h1 className="text-center">First Slide</h1>,
            className: 'active',
            slideInterval: 2000
        },
        {
            title: 'Second Slide',
            component: <h1 className="text-center">Second Slide</h1>,
            className: '',
            slideInterval: 3000
        },
        {
            title: 'Third Slide',
            component: <h1 className="text-center">Third Slide</h1>,
            className: '',
            slideInterval: 4000
        }
    ],
    CarouseId: 'carouselExampleIndicators'
};