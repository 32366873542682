import CustomCard from "../../Common/CustomCard";
import CustomCarousel from "../../Common/CustomCarousel";

const DashboardPageRight: React.FC = () => {

    const cardSetting = {
        mainCardProps: {
            style: {
                height: 400,
            }
        },
        childrenBodyContainerProps: {
            style: {
                overflow: 'auto',
                height: 350
            }
        },
    }

    function Component({ title }: { title: string }) {
        return (
            <CustomCard title={title} {...cardSetting}>
                {
                    Array(15).fill(0).map((item, index) => {
                        return (
                            <ul className="list-group list-group-flush" key={index}>
                                <li className="list-group-item">Item {index}</li>
                            </ul>
                        )
                    })
                }
            </CustomCard>
        )
    }

    const array = [
        {
            component: <Component title={'Latest Course'} />,
            className: 'active',
            slideInterval: 2000
        },
        {
            component: <Component title={'Latest test'} />,
            className: 'active',
            slideInterval: 3000
        },
    ]

    return (
        <div className="row">
            <div className="col-lg-6 col-sm-12">
                <CustomCard title="Suggested List" {...cardSetting}>
                    {
                        Array(15).fill(0).map((item, index) => {
                            return (
                                <ul className="list-group list-group-flush" key={index}>
                                    <li className="list-group-item">Item {index}</li>
                                </ul>
                            )
                        })
                    }
                </CustomCard>
            </div>
            <div className="col-lg-6 col-sm-12">
                <CustomCarousel CarouseId="dashboardRight" CarouselArray={array} />
            </div>
        </div>
    );
}

export default DashboardPageRight;