import React from 'react'
import { DashPageCenter, DashboardLeft, DashboardPageRight, Layout } from '../Components'

const DashPage = () => {
    return (
        <Layout>
            <div className='d-flex row' >
                <div className='col-lg-4 col-sm-12 g-sm-4' >
                    <DashboardLeft />
                </div>
                <div className='col-lg-8 col-sm-12 g-sm-4' >
                    <DashPageCenter />
                </div>
                <div className='col-lg-12 col-sm-12 g-sm-4' >
                    <DashboardPageRight />
                </div>
            </div>
        </Layout>
    )
}

export default DashPage
