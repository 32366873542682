import { createAsyncThunk } from "@reduxjs/toolkit";
import { Instance } from "./URL_config";

export const Login = createAsyncThunk(
    "auth/Login",
    async (data: { username: string; password: string }, thunkAPI) => {
        try {
            const response = await Instance.post("api/login?s=3", data);
            return response.data;
        } catch (error) {
            console.log("Response from Login Error in Login", error);
        }
    }
);

export const RegisterByEmail = createAsyncThunk(
    "auth/RegisterByEmail",
    async (data: { emailid: string | null | undefined }, thunkAPI) => {
        try {
            const response = await Instance.post("api/register?s=3", data);
            console.log("Response from RegisterByEmail", response.data);
            return response.data;
        } catch (error) {
            console.log("Response from RegisterByEmail Error in RegisterByEmail", error);
        }
    }
);